<template>
  <b-container class="py-bw-5" id="contactBW">
    <b-form
      novalidate
      @submit.prevent="sendEmail"
    >
      <b-row class="mb-5">
        <h2 class="h2-bw mx-auto w-100">
          {{ $t('contact.header.title') }}
        </h2>
        <b-col class="mt-5 text-left">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group :class="{ 'form-group--error': $v.firstName.$error }">
                <label for="input-firstName" class="mt-3 p-bw-bold">First Name</label>
                <b-form-input
                  v-model.trim="$v.firstName.$model"
                  class="input-bw"
                ></b-form-input>
                <div v-if="!!$v.firstName.$dirty" class="input-error-group">
                  <div
                    class="input-error"
                    v-if="!$v.firstName.required"
                  >
                    First Name is required
                  </div>
                  <div
                    class="input-error"
                    v-if="!$v.firstName.minLength"
                  >
                    First Name must have at least {{$v.firstName.$params.minLength.min}} letters.
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group :class="{ 'form-group--error': $v.lastName.$error }">
                <label for="input-lastName" class="mt-3 p-bw-bold">Last Name</label>
                <b-form-input
                  v-model.trim="$v.lastName.$model"
                  class="input-bw"
                ></b-form-input>
                <div v-if="!!$v.lastName.$dirty" class="input-error-group">
                  <div
                    class="input-error"
                    v-if="!$v.lastName.required"
                  >
                    Last Name is required
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group :class="{ 'form-group--error': $v.email.$error }">
            <label for="input-email" class="mt-3 p-bw-bold">Email</label>
            <b-form-input
              v-model.trim="$v.email.$model"
              class="input-bw"
              type="email"
              id="input-email"
            ></b-form-input>
            <div v-if="!!$v.email.$dirty" class="input-error-group">
              <div
                class="input-error"
                v-if="!$v.email.required"
              >
                Email is required
              </div>
              <div
                class="input-error"
                v-if="!$v.email.email"
              >
                Must be a valid email.
              </div>
            </div>
          </b-form-group>

          <b-form-group>
            <label for="input-message" class="mt-3 p-bw-bold">Message</label>
            <b-form-textarea
              v-model="message"
              class="input-bw"
              rows="3"
              id="input-message"
              aria-describedby="input-message-help input-message-contact"
              trim
            ></b-form-textarea>
          </b-form-group>

        </b-col>
      </b-row>
      <div class="contact-actions">
        <b-button
          variant="dark"
          class="contact-actions__button1"
          type="submit"
          :disabled='$v.$invalid || submit !== "Submit"'
        >
          {{ submit }}
        </b-button>
      </div>
    </b-form>
    <b-modal id="contactSubmit" hide-header hide-footer>
      <div>
        <p class="p-bw">Thank you for writing us. We will write you back soon!</p>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import emailjs from 'emailjs-com'
import { required, minLength, email } from 'vuelidate/lib/validators'
export default {
  name: 'Contact',
  components: {},
  data () {
    return {
      firstName: null,
      lastName: null,
      email: null,
      message: null,
      submit: 'Submit'
    }
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(3)
    },
    lastName: {
      required
    },
    email: {
      required,
      email
    }
  },
  methods: {
    sendEmail () {
      try {
        this.submit = 'Sending...'
        const serviceId = 'service_td1gb7n'
        const templateId = 'template_smgivlg'
        const userId = 'user_slZ2frUccuhgXBmMtv5hF'
        const templateParams = {
          from_name: `${this.firstName} ${this.lastName}`,
          message: this.message,
          email: this.email
        }
        emailjs.send(serviceId, templateId, templateParams, userId)
          .then((response) => {
            this.$bvModal.show('contactSubmit')
            // Reset form field
            // this.firstName = null
            // this.lastName = null
            // this.email = null
            this.message = null
            this.submit = 'Submit'
            console.log('SUCCESS!', response.status, response.text)
          })
          .catch((error) => {
            this.submit = 'Submit'
            console.log('FAILED...', error)
          })
      } catch (error) {
        console.log({ error })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-group {
    input, textarea {
      font-family: $bw-roboto;
      font-size: 1rem;
    }
    &--error {
      input {
        border-color: salmon;
        &:focus {
          border-color: salmon;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(250, 128, 114, .25);
        }
      }

      .input-error-group {
        .input-error {
          color: salmon;
          font-family: $bw-roboto;
          font-size: 1rem;
          font-weight: normal;
          letter-spacing: 1px;
          text-transform: none;
        }
      }
    }
  }
  .contact-actions {
    display: grid;

    @include breakpoint(md) {
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 2.5rem;
    }

    &__button1 {
      @include breakpoint(md) {
        grid-column: 2;
      }
    }
  }
</style>
